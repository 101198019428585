<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="height: 40px;">
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '设备管理') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '设备列表') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '添加设备') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="formData" status-icon label-width="160px" ref="ruleForm" :rules="rules"
      :style="'height:' + $store.state.clientHeight + 'px;'" class="form">
      <el-form-item :label="fun.getLanguageText($store.state.language, '设备类型')" prop="deviceTypeId">
        <el-select v-model="formData.deviceTypeId" value-key="name"
          :placeholder="fun.getLanguageText($store.state.language, '请选择设备类型')"
          @change="changeDevice(1, formData.deviceTypeId)">
          <el-option :label="item.name" :value="item.id" v-for="item in deviceTypeList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '设备名称')" prop="deviceNameId">
        <el-select v-model="formData.deviceNameId" value-key="name"
          :placeholder="fun.getLanguageText($store.state.language, '请选择设备名称')"
          @change="changeDevice(2, formData.deviceNameId)">
          <el-option :label="item.name" :value="item.id" v-for="item in deviceNameList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '设备型号')" prop="deviceModelId">
        <el-select v-model="formData.deviceModelId" value-key="name"
          :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
          @change="changeDevice(3, formData.deviceModelId)">
          <el-option :label="item.name" :value="item.id" v-for="item in deviceModelList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '设备序列号')" prop="sn">
        <el-input v-model="formData.sn" :placeholder="fun.getLanguageText($store.state.language, '请输入序列号')"></el-input>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, 'IMEI')" prop="imei">
        <el-input v-model="formData.imei" :placeholder="fun.getLanguageText($store.state.language, '请输入IMEI')"></el-input>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '客户公司')" prop="customerId"
        v-if="$store.state.roleId != 3">
        <el-select v-model="formData.customerId" :placeholder="fun.getLanguageText($store.state.language, '请选择客户公司')"
          @change="changeCorporateName">
          <el-option :label="item.companyName" :value="item.id" v-for="item in corporateNameList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '终端公司名称')" prop="clientId">
        <el-select v-model="formData.clientId" placeholder="请选择终端用户公司"
          :placeholder="fun.getLanguageText($store.state.language, '请选择客户公司')">
          <el-option :label="item.companyName" :value="item.id" v-for="item in clientIdList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '国家')" prop="countryId">
        <el-select v-model="formData.countryId" value-key="name"
          :placeholder="fun.getLanguageText($store.state.language, '请选择国家')" @change="changeCountry">
          <el-option :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id"
            v-for="item in countryList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '省份')" prop="provinceId">
        <el-select filterable v-model="formData.provinceId" value-key="name"
          :placeholder="fun.getLanguageText($store.state.language, '省份')" @change="changeProvince($event)">
          <el-option v-for="item in provinceList" :label="$store.state.language == 1 ? item.name : item.name_en"
            :value="item.id" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '城市')" prop="city">
        <el-select v-model="formData.cityId" :placeholder="fun.getLanguageText($store.state.language, '请选择城市')">
          <el-option :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id"
            v-for="item in cityList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '设备密码')" prop="secret">
        <el-input v-model="formData.secret"
          :placeholder="fun.getLanguageText($store.state.language, '请输入设备密码进行验证')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" @click="go">{{ fun.getLanguageText($store.state.language, '取消') }}</el-button>
        <el-button type="primary" @click="sub">{{ fun.getLanguageText($store.state.language, '确认') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        /*
        deviceTypeId: [{
          required: true,
          message: '账号不能为空',
          trigger: 'change'
        }]
        */
      },
      formData: {
        deviceTypeId: '', // 用户种类id
        deviceNameId: '', // 名称ID
        deviceModelId: '', // 型号ID
        sn: '', // 序列号
        imei: '', // imei
        customerId: '', // 客户ID
        clientId: '', // 终端ID
        countryId: '', // 国家ID
        provinceId: '', // 省份ID
        cityId: '', // 城市ID
        secret: '' // 密码
      },
      deviceTypeList: [], // 用户种类列表
      deviceNameList: [], // 用户名称列表
      deviceModelList: [], // 用户型号列表
      countryList: [], // 国家列表
      provinceList: [],
      cityList: [], // 城市列表
      countryPath: '', // 当前选中国家路径
      provincePath: '', // 当前选择省份路径
      corporateNameList: [], // 客户名称列表
      clientIdList: [] // 终端用户列表
    }
  },
  created() {
    this.getDeviceType(1)
    this.getCountryList()
    this.getUserList()
    if (this.$store.state.roleId == 3) {
      this.getClientList()
    }
  },
  methods: {
    // 省份被改变
    changeProvince(e, req) {
      console.log("改变省份", req, this.provinceList.length)
      this.provinceList.forEach(item => {
        if (item.id == e) {
          this.formData.province = item.id + ""
          // 设置筛选城市使用ID
          if (!req) {
            this.formData.cityId = ''
          }
          this.provincePath = item.path
          this.getCityList()
        }
      })
    },
    // 客户名称呗修改
    changeCorporateName(e) {
      console.log(e)
      this.clientIdList = []
      this.formData.clientId = ""
      this.getClientList()

    },
    // 读取设备类型
    async getDeviceType(type, pid) {
      let ret = await this.$http.post('device-type/list', {
        type,
        pid
      })
      ret = ret.data
      if (ret.code != 2000) {
        return
      }
      if (type === 1) {
        this.deviceTypeList = ret.data
      }
      if (type === 2) {
        this.deviceNameList = ret.data
      }
      if (type === 3) {
        this.deviceModelList = ret.data
      }
    },
    // 设备类型,名称,型号 下拉修改
    async changeDevice(type, e) {
      if (type === 1) { // 设备类型更新
        this.formData.deviceNameId = ''
        this.formData.deviceModelId = ''
        this.getDeviceType(2, this.formData.deviceTypeId)
      }
      if (type === 2) { // 设备名称更新
        this.formData.deviceModelId = ''
        this.getDeviceType(3, this.formData.deviceNameId)
      }
    },
    // 读取客户列表
    async getUserList() {
      let ret = await this.$http.post('company/select-customer', {})
      console.log(ret)
      if (ret.data.code != 2000) {
        return false
      }
      this.corporateNameList = ret.data.data
    },
    // 读取终端用户列表
    async getClientList() {
      let ret = await this.$http.post('company/select-client', {
        pid: this.formData.customerId
      })
      console.log(ret)
      if (ret.data.code != 2000) {
        return false
      }
      this.clientIdList = ret.data.data
    },
    // 读取国家
    async getCountryList() {
      let ret = await this.$http.post('region/select-country', {})
      ret = ret.data
      if (ret.code != 2000) {
        console.log(ret)
        return
      }
      this.countryList = ret.data
    },
    // 当前国家被选中
    changeCountry(e) {
      this.countryList.forEach(item => {
        if (item.id === e) {
          this.countryPath = item.path
          this.getProvinceList()
          this.formData.cityId = ''
          this.formData.provinceId = ''
          this.cityList = []
          console.log(this.countryPath)
        }
      })
    },
    // 获取省份列表
    async getProvinceList() {
      this.$http.post('region/select-province', {
        path: this.countryPath,
      }).then(res => {
        if (res.data.msg == 'success') {
          console.log('获取省份列表')
          this.provinceList = res.data.data
        }
      }).catch(err => {
      })
    },
    // 读取城市
    async getCityList() {
      let ret = await this.$http.post('region/select-city', {
        path: this.provincePath
      })
      ret = ret.data
      if (ret.code != 2000) {
        console.log(ret)
        return
      }
      this.cityList = ret.data
    },
    async sub() {
      console.log(this.formData)
      let res = await this.$http.post('device/add', this.formData)
      console.log(res)
      res = res.data
      if (res.code != 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language, res.msg));
        return
      }
      this.$message({
        type: 'success',
        message: this.fun.getLanguageText(this.$store.state.language, '添加设备成功')
      });
      this.$router.push('DeviceList')
    },
    go() {
      this.$router.go(-1)
    },
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        let h = Html[0].clientHeight - 95
        this.$store.commit('setClientHeight', Number(h) + 1)
        console.log(this.$store.state.clientHeight)
        this.$forceUpdate()
      }
    }
  },
  mounted() {
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
  }
}
</script>

<style lang="less" scoped>
.form {
  overflow-x: hidden;
  overflow-y: scroll;
}

.el-form-item {

  .el-input,
  .el-select {
    width: 500px;
  }

  .el-button {
    width: 200px;
    margin-right: 90px;
  }

}
</style>
